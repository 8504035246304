
/**
 * Injects a replacement DOM into the target node. Why use a function for such
 * a simple operation? Because of script extraction - we need to do this so that
 * we can ensure that scripts are appended as new nodes so they are executed.
 *
 * @param {Node} target - Where the replacement should occur. This node's contents will be replaced.
 * @param {Node} replacementDom - The new DOM to insert.
 */
export function injectDom(target, replacementDom) {
  const scripts = extractScripts(replacementDom);
  target.innerHTML = "";
  target.appendChild(replacementDom);
  // We need to attach scripts individually so that they're executed.
  scripts.forEach(script => target.appendChild(script));
}

/**
 * Extract out scripts from the DOM so they can be injected one-by-one. This will return the
 * script tags and also remove them from the DOM node passed in.
 *
 * @param {Node} replacementDom
 */
export function extractScripts(replacementDom) {
  return [...replacementDom.querySelectorAll("script")].map((node) => {
    // First, remove the script from the replacement dom (we'll insert it individually ourselves)
    node.parentNode.removeChild(node);
    // Construct a new script node to be inserted into the DOM.
    const newNode = document.createElement('script');
    newNode.appendChild(document.createTextNode(node.innerText));
    return newNode;
  });
}
