import { Controller } from "stimulus";
import { delegate } from 'utils/delegate';
import { injectDom } from "utils/injectDom";
import { logEvent } from "utils/analytics";
import confetti from "canvas-confetti";
export default class extends Controller {
  static targets = ["content", "loading", "modal"];

  connect() {
    if (this.element.dataset.showOnLoad === "true") {
      this.changeVisibility('loaded');
    } else {
      delegate({
        selector: '.modal-link',
        eventName: 'click',
        handler: this.renderModal.bind(this)
      });
    }
  }

  async renderModal(e) {
    e.preventDefault();
    this.changeVisibility('loading');

    this.currentModalLink = e.target.closest('a');
    logEvent("Modal - Loading", { url: this.currentModalLink.href });

    const newDom = await this.fetchModal();
    await injectDom(this.contentTarget, newDom);
    
    if (window.supercast) {
      window.supercast.trigger('pageload');
      window.supercast.trigger('modal-loaded');
    }
    logEvent("Modal - Loaded", { url: this.currentModalLink.href });
    this.changeVisibility('loaded');

    // Reset hasInteracted since this is a new modal.
    this.hasInteracted = false;
  }

  interacted() {
    if (this.hasInteracted === false) {
      logEvent("Modal - Interaction", { url: this.currentModalLink.href });
      this.hasInteracted = true;
    }
  }

  async fetchModal() {
    const url = this.currentModalLink.href.toString();
    const response = await fetch(url, { headers: { 'Accept': 'text/html' } });
    const html = await response.text();
    const dom = document.createElement('div');
    dom.innerHTML = html;
    const replacement = dom.querySelector(".modal-content");
    if (!replacement) {
      throw new Error("Could not find modal content in response");
    }
    return replacement;
  }
  
  close(e) {
    e.preventDefault();
    logEvent("Modal - Closed", { url: this.currentModalLink?.href });

    // This should keep the turbo frame intact while still getting rid of all 
    // modal content.
    this.contentTarget.innerHTML = "";
    if (this.element.dataset.showOnLoad === "true") {
      this.element.remove();
    }
    this.changeVisibility('closed');
  }

  changeVisibility(state) {
    if (state === 'loaded') {
      this.loadingTarget.hidden = true;
      this.modalTarget.hidden = false;
      this.element.hidden = false;
      this.modalTarget.classList.remove(...['opacity-0','scale-50']);
      this.modalTarget.classList.add(...['opacity-100', 'scale-100']);
      this.maybeShowConfetti();
    } else if (state === 'loading') {
      this.loadingTarget.hidden = false;
      this.modalTarget.hidden = false;
      this.element.hidden = false;
    } else if (state === 'closed') {
      this.loadingTarget.hidden = true;
      this.modalTarget.hidden = true;
      this.element.hidden = true;
      this.modalTarget.classList.add(...['opacity-0', 'scale-50']);
      this.modalTarget.classList.remove(...['opacity-100', 'scale-100']);
    }
  }

  maybeShowConfetti() {
    if (this.element.dataset.confetti === "true") {
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0 }
      });
      confetti({
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1 }
      });
    }
  }
}
