import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.key = this.element.dataset.featureKey;

    if (this.shouldDisplay()) {
      this.show();
    }
  }

  shouldDisplay() {
    return window.localStorage.getItem(`feature-callout-dismissed-${this.key}`) === null;
  }

  dismiss(e) {
    e.preventDefault();
    window.localStorage.setItem(`feature-callout-dismissed-${this.key}`, "true");
    this.close();
  }

  close() {
    this.element.classList.add("invisible");
    this.element.classList.add("opacity-0");
  }

  show() {
    this.element.classList.remove("invisible");
    this.element.classList.remove("opacity-0");
  }
}
