import { Controller } from "stimulus"

function classStringToArray(classString) {
  if (!classString) return []
  const s = classString.trim()

  if (s.length == 0) return []
  return s.split(" ")
}

export default class ExpandableContent extends Controller {
  static targets = ["toggle", "content"]

  connect() {
    this.contentMaxHeight = this.element.dataset.contentMaxHeight || 400
    this.prepare()
  }

  prepare() {
    if (this.contentTarget.offsetHeight > this.contentMaxHeight) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand(e) {
    e && e.preventDefault();
    this.toggleTargets.forEach(target => {
      target.classList.remove(...classStringToArray(target.dataset.collapsed))
      target.classList.add(...classStringToArray(target.dataset.expanded))
    })
  }

  collapse(e) {
    e && e.preventDefault();
    this.toggleTargets.forEach(target => {
      target.classList.remove(...classStringToArray(target.dataset.expanded))
      target.classList.add(...classStringToArray(target.dataset.collapsed))
    })
  }
}
