import { Controller } from "stimulus";

export default class ConnectLink extends Controller {
  connect() {
    this.element.addEventListener("click", this.clickLink.bind(this));
    // Do an initial status check to check off anything previously connected.
    this.checkStatus();
  }

  clickLink(e) {
    this.setStatus("loading");
    this.pollingInterval = window.setInterval(this.checkStatus.bind(this), 5000);
  }

  async checkStatus() {
    const response = await fetch(this.element.dataset.statusUrl);
    const data = await response.json();
    if (data.status == "connected") {
      this.setStatus("done");
    }
  }

  setStatus(status) {
    const addButton = this.element.querySelector(".add-button");
    const controller = this.application.getControllerForElementAndIdentifier(addButton, "add-button");

    controller.setStatus(status);
    window.clearInterval(this.pollingInterval);
  }
}