import { Controller } from 'stimulus';
import { domain } from 'utils/environment';
import Mailcheck from 'mailcheck';

export default class EmailValidationController extends Controller {
  static targets = ['email', 'error', 'signUp', 'emailSuggestion'];

  validate() {
    this.checkForMisspellings();
    this.checkUniqueness();
  }

  checkForMisspellings() {
    Mailcheck.run({
      email: this.emailTarget.value,
      suggested: this.setEmailSuggestion.bind(this),
      empty: this.clearEmailSuggestion.bind(this)
    })
  }

  setEmailSuggestion(suggestion) {
    this.emailSuggestionTarget.innerHTML = `Did you mean <a href='#' class='suggestion'>${suggestion.full}</a>?`
    this.emailSuggestionTarget.querySelector('.suggestion').addEventListener('click', (e) => {
      e.preventDefault();
      this.emailTarget.value = e.target.textContent;
      this.clearEmailSuggestion();
    });
  }

  clearEmailSuggestion() {
    this.emailSuggestionTarget.innerHTML = "";
  }

  async checkUniqueness() {
    // Note: Since this may be embedded, we need to use absolute URLs.
    const uri = new URL('/subscriptions/valid', domain(this.element.dataset.subdomain, this.element.dataset.environment));
    uri.searchParams.set('subscription[plan_id]', this.element.dataset.planId);
    uri.searchParams.set('subscription[user][email]', this.emailTarget.value);

    const response = await fetch(uri);

    // If we get an OK response, we're all good.
    if (response.ok) {
      this.errorTarget.innerHTML = '';
      return;
    }

    const json = await response.json();
    switch (json.error) {
      case 'already_subscribed':
        this.errorTarget.innerHTML = `You already have an account! Log in to listen and manage your subscription.`;
        this.signUpTarget.disabled = true;
        return false;
      default:
        this.errorTarget.innerHTML = `An unknown error occurred. Please contact support@supercast.com for help.`;
        return false;
    }
  }
}
