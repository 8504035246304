import { Controller } from "stimulus";
import { logEvent } from "utils/analytics";
import Appsignal from "utils/appsignal";
export default class extends Controller {
  static targets = [
    "cardElement",
    "paymentButtonElement",
    "firstNameInput",
    "lastNameInput",
    "emailInput",
    "paymentError",
    "signupButton",
    "paymentButtonContainer",
    "signupButtonContainer",
    "nameContainer",
    "progress",
    "termsContainer",
    "recaptcha",
    "couponContainer"
  ]

  static values = {
    account: String,
    currency: String,
    publicKey: String,
    intentSecret: String,
    intentType: String,
    amount: Number,
    description: String,
    interval: String
  }

  async connect() {
    const loadingTimeout = setTimeout(() => {
      this.setVisibility("card");
      this.showPaymentError("Sorry! We were unable to start payment. Please try again or <a href='https://support.supercast.com' data-controller='beacon-link' data-action='beacon-link#open'>contact us</a> for help.");
    }, 10000);

    logEvent("Payment - Connecting");
    this.setVisibility("loading");

    await Promise.all([
      this.waitForStripe(),
      this.waitForRecaptcha()
    ]);

    this.stripe = window.Stripe(this.publicKeyValue, { stripeAccount: this.accountValue });
    this.elements = this.stripe.elements();
    this.currentPaymentMethod = 'card';

    logEvent("Payment - Stripe / Recaptcha Ready");
    this.setupRecaptcha();
    this.setupCard();
    await this.setupPaymentButton();
    this.setVisibility(this.currentPaymentMethod);

    logEvent("Payment - Ready");

    this.element.addEventListener('submit', this.submitForm.bind(this));

    clearTimeout(loadingTimeout);
  }

  setupRecaptcha() {
    window.grecaptcha.render(this.recaptchaTarget, {
      sitekey: this.recaptchaTarget.dataset.sitekey,
      callback: 'captchaCallback',
      size: 'invisible'
    });

    window.captchaCallback = (captchaResult) => {
      logEvent("Payment - Recaptcha Completed", { captchaResult });
      this.element.submit();
    }
  }

  setupCard() {
    this.card = this.elements.create('card');
    this.card.mount(this.cardElementTarget);
    this.card.addEventListener('change', (e) => {
      if (e.error) {
        logEvent("Payment - Card Error", { message: e.error.message });
        this.paymentErrorTarget.textContent = e.error.message;
        this.paymentErrorTarget.classList.remove('hidden');
        this.signupButtonTarget.disabled = true;
      } else if (e.complete) {
        logEvent("Payment - Card Input Successful");
        this.paymentErrorTarget.textContent = '';
        this.paymentErrorTarget.classList.add('hidden');
        this.signupButtonTarget.disabled = false;
      }
    });
  }

  paymentRequestOptions() {
    const options = {
      total: {
        label: 'Total',
        amount: this.amountValue,
      },
      country: 'US',
      currency: this.currencyValue,
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link'],
    }

    if (this.intervalValue && (this.intervalValue === 'year' || this.intervalValue === 'month')) {
      options["applePay"] = {
        recurringPaymentRequest: {
          paymentDescription: this.descriptionValue,
          managementURL: `https://${window.location.host}/subscriber_v2/subscription`,
          regularBilling: {
            amount: this.amountValue,
            label: 'Subscription fee',
            recurringPaymentIntervalUnit: this.intervalValue,
            recurringPaymentIntervalCount: 1
          }
        }
      }
    }

    return options;
  }

  async setupPaymentButton() {
    this.paymentRequest = this.stripe.paymentRequest(this.paymentRequestOptions());

    this.paymentButton = this.elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    })
    const result = await this.paymentRequest.canMakePayment();
    if (result?.applePay || result?.googlePay) {
      this.canUsePaymentButton = true;
      this.currentPaymentMethod = 'button';
      this.paymentButton.mount(this.paymentButtonElementTarget);
    }

    this.paymentRequest.on('paymentmethod', (e) => {
      e.complete('success');
      this.paymentButtonPaymentMethod = e.paymentMethod
      this.fillFormFromPaymentMethod(e);
    });

    window.paymentRequest = this.paymentRequest;
  }

  waitForStripe() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.Stripe) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  ALL_VISIBILITY_ELEMENTS = [
    'cardElementTarget',
    'nameContainerTarget',
    'paymentButtonContainerTarget',
    'signupButtonContainerTarget',
    'progressTarget',
    'termsContainerTarget',
    'couponContainerTarget'
  ]

  VISIBILE_ELEMENTS_BY_MODE = {
    'card': ['cardElementTarget', 'nameContainerTarget', 'termsContainerTarget', 'signupButtonContainerTarget', 'couponContainerTarget'],
    'button': ['paymentButtonContainerTarget', 'termsContainerTarget', 'couponContainerTarget'],
    'loading': ['progressTarget']
  }

  setVisibility(mode) {
    logEvent("Payment - Setting Visibility", { mode });

    this.ALL_VISIBILITY_ELEMENTS.forEach((target) => {
      if (this["has" + target.charAt(0).toUpperCase() + target.slice(1)]) {
        this[target].classList.add('hidden');
      }
    });

    this.VISIBILE_ELEMENTS_BY_MODE[mode].forEach((target) => {
      if (this["has" + target.charAt(0).toUpperCase() + target.slice(1)]) {
        this[target].classList.remove('hidden');
      }
    });
  }

  async fillFormFromPaymentMethod(e) {
    const form = this.element;

    if (e.payerName && e.payerName.indexOf(" ") > -1) {
      const trimmedName = e.payerName.trim();
      this.firstNameInputTarget.value = trimmedName.substring(0, trimmedName.lastIndexOf(' '));
      this.lastNameInputTarget.value = trimmedName.substring(trimmedName.lastIndexOf(' ') + 1, trimmedName.length);
    }
    if (e.payerEmail && e.payerEmail.length > 1) {
      this.emailInputTarget.value = e.payerEmail;
    }

    if (
      this.firstNameInputTarget.value.length === 0 ||
      this.lastNameInputTarget.value.length === 0 ||
      this.emailInputTarget.value.length === 0
    ) {
      // We didn't get enough info from Stripe, show the name input and prevent submission
      this.nameContainerTarget.classList.remove('hidden');
      return;
    }

    if (form.elements["terms_of_use_accepted"] && form.elements["terms_of_use_accepted"].checked === false) {
      // Show the signup container so the uesr can accept terms of use and continue.
      this.signupButtonContainerTarget.classList.add('hidden');
      this.signupButtonTarget.disabled = true;
      this.paymentButtonElementTarget.classList.add('hidden');

      this.paymentErrorTarget.textContent = "Please accept the Supercast Terms of Use and Privacy Policy before continuing.";
      this.paymentErrorTarget.classList.remove('hidden');
      return;
    }

    await this.completeSubscription(form);
  }

  switchToCard(e) {
    logEvent("Payment - Switching to Card");
    e.preventDefault();
    this.currentPaymentMethod = 'card';
    this.setVisibility(this.currentPaymentMethod);
  }

  listenForCompleteUserInfo() {
    const form = this.element;

    form.querySelectorAll("[required]").forEach(el => {
      if (el.value.length < 1 || (el.type == 'checkbox' && !el.checked)) {
        this.addInlineError(el, "This field is required");
      } else {
        this.removeInlineError(el);
      }
    });

    form.querySelectorAll("input[type='number'][min]").forEach(el => {
      if (el.value !== "" && el.valueAsNumber < parseInt(el.min)) {
        this.addInlineError(el, `This field must be at least ${el.min}`);
      } else {
        this.removeInlineError(el);
      }
    });

    // Additionally check e-mails
    // TODO: Move all e-mail inputs to type=email so we don't repeat this logic.
    const email = form.querySelector(".email-input")
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email) {
      if (!emailRegex.test(email.value.toLowerCase())) {
        this.addInlineError(email, "Email is not a valid email");
      } else { this.removeInlineError(email) }
    }

    form.querySelectorAll("input[type='email'][required]").forEach(el => {
      if (!emailRegex.test(el.value.toLowerCase())) {
        this.addInlineError(el, "Email is not a valid email");
      } else { this.removeInlineError(el) }
    })

    // Validate fields that should not match (to_email and from_email on gift is an example)
    form.querySelectorAll("[data-notmatch]").forEach(el => {
      if (document.querySelector(el.dataset.notmatch).value == el.value) {
        this.addInlineError(el, el.dataset.notmatchMessage || `${el.value} is invalid`);
      }
    });

    // return false when there is no errors and terms is checked
    if (form.querySelector(".inline-error") === null) {
      return false;
    } else { return true; }
  }

  addInlineError(element, errorMessage) {
    const currentError = element.parentNode.querySelector(".inline-error")

    element.classList.add("input-error")
    if (currentError === null) {
      element.insertAdjacentHTML("afterend", `<small class='inline-error text-red-500 text-sm'>${errorMessage}</small>`)
    }
  }

  removeInlineError(element) {
    const currentError = element.parentNode.querySelector(".inline-error")

    if (currentError !== null) { currentError.remove(); }
    element.classList.remove("input-error")
  }

  submitForm(e) {
    if (!e.submitter || !e.submitter.formNoValidate) {
        e.preventDefault();
        this.completeSubscription();
    }
  }

  showPaymentError(message) {
    this.paymentErrorTarget.innerHTML = message;
    this.paymentErrorTarget.classList.remove('hidden');
    this.signupButtonTarget.disabled = false;
    this.setVisibility(this.currentPaymentMethod);
  }

  async completeSubscription() {
    try {
      logEvent("Payment - Completing Subscription", { paymentMethod: this.currentPaymentMethod });

      if (this.listenForCompleteUserInfo()) {
        return false;
      }

      this.setVisibility('loading');
      this.signupButtonTarget.disabled = true;

      let paymentMethod;
      if (this.currentPaymentMethod == 'card') {
        paymentMethod = {
          card: this.card,
          billing_details: {
            name: this.firstNameInputTarget.value + ' ' + this.lastNameInputTarget.value
          }
        }
      } else {
        paymentMethod = this.paymentButtonPaymentMethod.id;
      }

      // Confirm the card client-side so that we know we're OK on the server.
      const result = this.intentTypeValue == 'setup' ?
        await this.stripe.confirmCardSetup(this.intentSecretValue, { payment_method: paymentMethod }) :
        await this.stripe.confirmCardPayment(this.intentSecretValue, { payment_method: paymentMethod, setup_future_usage: 'off_session' });

      if (result.error) {
        this.showPaymentError(result.error.message);
      } else {
        window.grecaptcha.execute();
      }
    } catch (error) {
      this.showPaymentError("Sorry! We were unable to complete your purchase. Please try again or <a href='https://support.supercast.com' data-controller='beacon-link' data-action='beacon-link#open'>contact us</a> for help.");
      logEvent("Payment - Error", { message: error.message });
      Appsignal.sendError(error, (span) => {
        span.setAction("completeSubscription");
      });
      return false;
    }
  }

  waitForRecaptcha() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (window.grecaptcha && window.grecaptcha.render) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }
}
