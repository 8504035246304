import QRCodeStyling from "qr-code-styling";

export default function generateQRCode(element, url) {
  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: "svg",
    data: url,
    dotsOptions: {
      type: "extra-rounded",
      color: "#475058"
    },
    cornersSquareOptions: {
      color: "#6f7479",
      type: "extra-rounded"
    },
  });

  element.innerHTML = "";
  qrCode.append(element);
}