import { Controller } from "stimulus";

export default class NotificationController extends Controller {
  connect() {
    window.setTimeout(() => {
      this.close();
    }, 3000);
  }

  close() {
    this.transition({ element: this.element, from: ["opacity-100"], to: ["opacity-0"], hideAfter: 100 });
  }

  transition({ element, from, to, hideAfter = null }) {
    element.classList.remove(...from);
    element.classList.add(...to);
    if (hideAfter) {
      setTimeout(() => {
        element.classList.add("hidden");
      }, hideAfter);
    }
  }
}