/**
 * Toggles amounts for donations / extra funds for subscriptions.
 */

import { Controller } from "stimulus"
export default class extends Controller {

  static targets = ["custom", "price", "error"];

  connect() {
    this.basePrice = parseInt(this.element.dataset.basePrice);
    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.addEventListener('change', (e) => {
        this.useAnchor(e);
        this.updatePreview(e);
      });
    });
  }

  useCustom() {
    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.checked = false;
    });
    if (this.customTarget.value === "") {
      this.customTarget.value = this.customTarget.min;
    }
  }

  useAnchor() {
    this.customTarget.value = "";
  }

  currentExtraSupport() {
    const radio = this.element.querySelector("input[type='radio']:checked");
    const value = radio ? radio.value : (this.customTarget.value * 100) - this.basePrice;
    return value;
  }

  formattedBasePrice() {
    return (this.basePrice / 100).toFixed(2);
  }

  updatePreview() {
    this.errorTarget.classList.add("hidden");

    const currentExtraSupport = this.currentExtraSupport();

    if (currentExtraSupport < 0) {
      this.errorTarget.textContent = "Enter a value greater than the base price ($" + this.formattedBasePrice() + ")";
      this.errorTarget.classList.remove("hidden");
      return;
    }

    this.priceTargets.forEach((target) => {
        const url = new URL(target.src, window.location.href);
        url.searchParams.set('subscription[extra_support_cents]', currentExtraSupport);
        target.src = url.toString();
    });
  }
}
