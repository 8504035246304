import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "arrow", "toggle"];

  connect() {
    let selected = this.element.dataset.expandContent === "true";
    this.hidden = !selected;
    this.setVisibility();
    window.addEventListener("supercast:offer:toggleDisplay", this.otherDisplayToggled.bind(this));
  }

  togglePrice(e) {
    e.preventDefault();

    let prices = this.element.querySelectorAll("[data-price]")
    prices.forEach((el) => el.hidden = !el.hidden);

    let toggles = this.element.querySelectorAll("[data-price-toggle]")
    toggles.forEach((el) => {
      if (el.dataset.priceToggle === "true") {
        el.classList.remove("bg-white", "text-accent");
        el.dataset.priceToggle = false;
      } else {
        el.classList.add("bg-white", "text-accent");
        el.dataset.priceToggle = true;
      }
    });
  }

  toggleDisplay(e) {
    e.preventDefault();

    this.hidden = !this.hidden;
    this.setVisibility();
    window.dispatchEvent(new CustomEvent("supercast:offer:toggleDisplay", { detail: { source: this, hidden: this.hidden }}));

    // Only scroll to the element if we're on mobile
    if (!this.hidden && window.screen.width <= 768) {
      // Wait for the next animation frame so everything can set their visibility
      window.requestAnimationFrame(() => this.element.scrollIntoView());
    }
  }

  otherDisplayToggled(e) {
    if (e.detail.source != this && e.detail.hidden == false) {
      this.hidden = true;
      this.setVisibility();
    }
  }

  setVisibility() {
    // Note: Important we're using tailwind classes here since we have md: overrides with those and we
    // want to make sure that this code doesn't interfere with desktop.
    if (this.hidden) {
      this.contentTarget.classList.add("hidden");
    } else {
      this.contentTarget.classList.remove("hidden");
    }

    if (!this.hidden) {
      this.arrowTarget.classList.add("rotate-90")
    } else {
      this.arrowTarget.classList.remove("rotate-90")
    }
  }

  async fetchModal() {
    const url = this.currentModalLink.href.toString();
    const response = await fetch(url, { headers: { 'Accept': 'text/html' } });
    const html = await response.text();
    const dom = document.createElement('div');
    dom.innerHTML = html;
    const replacement = dom.querySelector(".modal-content");
    if (!replacement) {
      throw new Error("Could not find modal content in response");
    }
    return replacement;
  }
  
  close(e) {
    e.preventDefault();

    // This should keep the turbo frame intact while still getting rid of all 
    // modal content.
    this.contentTarget.innerHTML = "";
    this.element.hidden = true;
  }
}
