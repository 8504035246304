import { Controller } from "stimulus";
import generateQRCode from "utils/qr_code";

export default class QRCodeController extends Controller {
  connect() {
    this.url = this.element.dataset.url;
    this.generateQRCode();
  }

  generateQRCode() {
    generateQRCode(this.element, this.url);
  }
}
