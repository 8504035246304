import { Controller } from 'stimulus';

export default class LoginCodeController extends Controller {
  static targets = ["code", "input"]

  submit() {
    const code = this.inputTargets.map(el => el.value).join("");
    if (code.length == 6) {
        this.codeTarget.value = code;
        this.element.submit();
    }
  }

  input(e) {
    if (e.target.value) {
        const index = this.inputTargets.indexOf(e.target);
        if (index < this.inputTargets.length - 1) {
            this.inputTargets[index+1].focus();
        }
        this.submit();
    }
  }

  paste(e) {
    e.preventDefault();

    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const pattern = new RegExp("\\s*[0-9]{6}\\s*");
    if (pattern.test(paste)) {
      const numbers = paste.split("");
      for (let i = 0; i < 6; i++) {
        this.inputTargets[i].value = numbers[i];
      }
      this.submit();
    }
  }

  back(e) {
    if (e.keyCode !== 8) return;
    if (e.target.value === "") {
        const index = this.inputTargets.indexOf(e.target);
        if (index > 0) {
            const previous = this.inputTargets[index-1];
            previous.value = "";
            previous.focus();
        }
    }
  }

}
