/* eslint-disable no-undef */
import mixpanel from "mixpanel-browser";

let __hasBeenInitialized = false;

function initIfNeeded() {
  if (__hasBeenInitialized) {
    return true;
  }
  const element = document.getElementById("__mixpanel_key");
  if (element) {
    mixpanel.init(element.value);
    __hasBeenInitialized = true;
    return true;
  } else {
    return false;
  }
}

/**
 * Logs an event to analytics. Automatically injects the current subdomain and path
 *
 * @param {String} eventName - The name of the event to log
 * @param {Object} eventProperties - An object containing the properties of the event
 */
export function logEvent(eventName, eventProperties = {}) {
  if (!initIfNeeded()) { return;  }
  const { subdomain, path } = getSubdomainAndPath();

  mixpanel.track(eventName, {
    ...eventProperties,
    subdomain,
    path
  });

  maybeConsoleLog(eventName, eventProperties);
}

export function setUserProperties(userProperties) {
  if (!initIfNeeded()) { return; }
  mixpanel.people.set(userProperties);

  maybeConsoleLog('Set user properties', userProperties);
}

export function identify(id, userProperties = {}) {
  if (!initIfNeeded()) { return; }
  mixpanel.identify(id);

  if (Object.keys(userProperties).length > 0) {
    setUserProperties(userProperties);
  }

  // Using a windowed LogRocket since it will be up to the entrypoint to decide whether we're using LogRocket or not
  if (window.LogRocket) {
    maybeConsoleLog('LogRocket identify', { id, userProperties });
    window.LogRocket.identify(id, {
      name: userProperties['$name'],
      email: userProperties['$email']
    });

    // Additionally log session URL to Beacon
    if (window.Beacon) {
      window.LogRocket.getSessionURL(function (sessionURL) {
        window.Beacon("identify", {
          email: userProperties['$email'],
          name: userProperties['$name'],
          "admin-link": "https://app.supercast.com/admin/users/" + id,
          "logrocket-session-url": sessionURL
        });
      });
    }
  }

  maybeConsoleLog('Identify', { id });
}

/**
 * If the TLD of the the current domain is .test (i.e. we're in dev), log to the console
 */
function maybeConsoleLog(eventName, eventProperties) {
  if (window.location.hostname.endsWith('.test')) {
    console.log(`Analytics event: ${eventName}`, eventProperties);
  }
}

/**
 * Extracts the current subdomain and path from window.location
 */
function getSubdomainAndPath() {
  const { hostname } = window.location;
  const [subdomain, ...path] = hostname.split('.');

  return {
    subdomain,
    path: path.join('.')
  };
}
