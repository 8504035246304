import { Controller } from "stimulus"

export default class AlertBarController extends Controller {
  static values = {
    dismissIdentifier: String
  }

  connect() {
    if (window.localStorage.getItem(`alert_bar_${this.dismissIdentifierValue}_dismissed`) != 'true') {
      this.element.classList.remove('hidden');
    }
  }
  
  dismiss() {
    window.localStorage.setItem(`alert_bar_${this.dismissIdentifierValue}_dismissed`, 'true');
    this.element.classList.add('hidden');
  }
}