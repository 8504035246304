import { Controller } from 'stimulus';

/**
 * Logs events to analytics whenever this element is clicked. Specify the name
 * of the event in data-event. All other data properties will be sent to analytics as well.
 */
export default class CopyToClipboard extends Controller {
  async click(e) {
    this.element.querySelector('.clipboard-data').select();
    document.execCommand('copy');

    e.target.textContent = 'Copied!'
    setTimeout(() => e.target.textContent = "Copy", 3000);
  }
}
