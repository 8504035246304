import { Controller } from "stimulus";
import { loadStripe } from '@stripe/stripe-js';
import { logEvent } from 'utils/analytics';

export default class extends Controller {
  static targets = ['error', 'errorContainer'];

  async connect() {
    this.stripe = await loadStripe(this.element.dataset.stripeKey, { stripeAccount: this.element.dataset.stripeAccount });
    this.confirmSubscription();
  }

  async confirmSubscription() {
    const paymentResult = await this.stripe.confirmPayment({
      clientSecret: this.element.dataset.clientSecret,
      confirmParams: {
        payment_method: this.element.dataset.paymentMethod,
        return_url: window.location.href,
      },
      redirect: 'if_required'
    });

    if (paymentResult.error) {
      logEvent('Payment - Confirmation Error', { error: paymentResult.error });
      this.errorTarget.textContent = paymentResult.error.message;
      this.errorTarget.style.display = 'block';
      if (this.hasErrorContainerTarget) {
        this.errorContainerTarget.style.display = 'block';
      }
    } else {
      logEvent('Payment - Confirmed');
      this.element.submit();
    }
  }
}