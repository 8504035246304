import { Turbo } from "@hotwired/turbo-rails";
import "packs/engines/signup/controllers";
import "utils/supercast";
import StripePayment from 'utils/stripe_payment';
import LogRocket from 'logrocket';

import { logEvent, setUserProperties, identify } from "utils/analytics";

import "utils/frame_missing_handler";

if (document.cookie.indexOf('sc_debug') !== -1) {
  window.LogRocket = LogRocket;
  window.LogRocket.init('supercast/web-app-2');
}

window.Turbo = Turbo;

window.initStripe = function (args) {
  window.stripePayment = new StripePayment(args);
  window.stripePayment.setup();
}

window.analytics = { logEvent, setUserProperties, identify };
