/* eslint-disable no-undef */
import { Controller } from 'stimulus';
import { logEvent } from 'utils/analytics';

export default class BeaconLink extends Controller {
  static values = { article: String }

  open(e) {
    e.preventDefault();

    logEvent("Get Help - Opened Beacon", { location: "Message", subject: this.element.getAttribute('data-beacon-subject') });

    this.prefillBeacon();

    Beacon('navigate', '/ask/message');
    Beacon('open');
  }

  prefillBeacon() {
    const subject = this.element.getAttribute('data-beacon-subject') || '';
    const category = this.element.getAttribute('data-beacon-category') || 'Other';

    Beacon('prefill', {
      subject,
    });

    // Add destination and help category to session data (tried this with custom fields, but we want to avoid showing
    // these to users)
    Beacon("session-data", {
      category
    });
  }

  openAnswers(e) {
    e.preventDefault();

    logEvent("Get Help - Opened Beacon", { location: "Answers" });

    Beacon('navigate', '/answers');
    Beacon('open');
  }

  showArticle(e) {
    e.preventDefault();

    logEvent("Get Help - Showing Article", { article: this.articleValue, articleTitle: e.target.textContent });

    Beacon('article', this.articleValue, { type: 'sidebar' });
  }

}
