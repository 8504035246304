/***
 * Handles cases where turbo can't find a corresponding turbo frame by just visiting the url directly.
 * There's two cases where we encounter this:
 *
 * - When an error occurs (in which case displaying the 500 / 404 / whatever page is correct)
 * - When a request is redirected (in which case we want to follow the redirect)
 *
 * In either case we should still log something to Appsignal since we don't want to rely on this
 * as normal control flow.
 *
 */

window.addEventListener("turbo:frame-missing", (event) => {
  event.preventDefault();
  const response = event.detail.response;

  event.detail.visit(response.url);
});
