/* eslint-disable no-undef */
import Appsignal from '@appsignal/javascript';

let appsignalKey;

switch (process.env.NODE_ENV) {
  case 'development':
  case 'test':
    appsignalKey = '1351c37f-234b-4022-aec3-0565a0ed6fdb';
    break;
  case 'staging':
    appsignalKey = '0d3a8090-4974-4e92-b75f-03cebdba89ea';
    break;
  case 'production':
    appsignalKey = '8a3ef517-ca57-47fc-bad1-96b4b5aaafe0';
    break;
}

export default new Appsignal({
  key: appsignalKey
})