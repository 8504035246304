import { Controller } from 'stimulus';

export default class ShareableLinkController extends Controller {
  connect() {
    if (navigator.share && this.isMobile()) {
      this.shareApiTarget.classList.remove("hidden")
    } else {
      this.socialShareTarget.classList.remove("hidden")
    }
  }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  share() {
    navigator.share({
      title: this.textValue,
      url: this.urlValue
    })
  }
}

ShareableLinkController.targets = ["shareApi", "socialShare"];
ShareableLinkController.values = { url: String, text: String };