/**
 * Toggles amounts for donations / extra funds for subscriptions.
 */

import { Controller } from "stimulus"
import { csrfFetch } from "utils/fetch";

export default class extends Controller {

  static targets = ["custom", "loading"];

  connect() {
    this.intentId = this.element.dataset["intentId"];

    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.addEventListener('change', () => {
        this.useAnchor();
        this.updateAmount();
      });
    });

    this.updateAmount();
  }

  useCustom() {
    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.checked = false;
    });
    if (this.customTarget.value === "") {
      this.customTarget.value = this.customTarget.min;
    }
  }

  useAnchor() {
    this.customTarget.value = "";
  }

  currentValue() {
    const radio = this.element.querySelector("input[type='radio']:checked");
    const value = radio ? radio.value : (this.customTarget.value) * 100;
    if (value > 0) {
      return value;
    }
  }

  async updateAmount() {
    this.loadingTarget.classList.remove("hidden");
    const amount = this.currentValue();
    const response = await csrfFetch(`/donations/update_amount?intent_id=${this.intentId}&amount_cents=${amount}`, {
      method: 'PUT'
    });
    if (response.status !== 200) {
      alert("We ran into a problem updating your donation amount. Please try again later.");
    }

    // Update the payment request client side so the right amount displays
    if (window.paymentRequest) {
      window.paymentRequest.update({
        total: {
          label: "Total",
          amount: parseInt(amount)
        }
      });
    }

    this.loadingTarget.classList.add("hidden");
  }
}
