import { Controller } from 'stimulus';

export default class Thumbnail extends Controller {
  connect() {
    this.staticSrc = this.imageTarget.src;
    this.animatedSrc = this.element.dataset["animatedSrc"];

    if (this.animatedSrc != "") {
      this.element.addEventListener('mouseenter', this.showAnimated.bind(this));
      this.element.addEventListener('mouseleave', this.showStatic.bind(this));
    }
  }

  showAnimated() {
    this.imageTarget.src = this.animatedSrc;
  }

  showStatic() {
    this.imageTarget.src = this.staticSrc;
  }
}

// Static target is the 'normal' thumbnail, animated is shown on hover.
Thumbnail.targets = ['image'];