import { Controller } from "stimulus";
import geocode from "utils/geocode";

export default class extends Controller {
  static targets = ["amount", "loading", "isoCode"];

  async connect() {
    if (this.hasIsoCodeTarget) {
      await this.toggleIsoCode();
    }

    this.amountTarget.classList.remove("hidden");
    this.loadingTarget.classList.add("hidden");
  }

  async toggleIsoCode() {
    const currentIsoCode = this.isoCodeTarget.innerText.trim();
    let shouldShowIso = false;
    if (currentIsoCode === "CAD" || currentIsoCode === "AUD") {
      shouldShowIso = true;
    }
    if (currentIsoCode === "USD") {
      shouldShowIso = await this.isUserOutsideUS();
    }
    if (shouldShowIso) {
      this.isoCodeTarget.classList.remove("hidden");
    } else {
      this.isoCodeTarget.classList.add("hidden");
    }
  }

  async isUserOutsideUS() {
    const geoCodeData = await geocode();
    return geoCodeData.country !== "US";
  }
}
