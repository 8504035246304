import Appsignal from 'utils/appsignal';
import { loadStripe } from '@stripe/stripe-js';
import { logEvent } from 'utils/analytics';

export default class StripePayment {

  ALL_SECTIONS = ['.name-container', '.signup-button-container', '.credit-card-container', '.payment-request-container'];

  // Parts of the form that should be displayed when there is a card input
  CARD_SECTIONS = ['.name-container', '.signup-button-container', '.credit-card-container'];
  // Parts of the form that should be displayed when there is a payment request
  PAYMENT_REQUEST_SECTIONS = ['.payment-request-container']
  // Parts of the form that should be displayed when using an existing card
  EXISTING_CARD_SECTIONS = ['.signup-button-container']

  constructor({ stripeAccount, publicKey, intentSecret, intentType, plan, price, paymentMethod, currency = 'usd', rootElement = document }) {
    this.stripeAccount = stripeAccount;
    this.publicKey = publicKey;
    this.intentSecret = intentSecret;
    this.intentType = intentType;
    this.plan = plan;
    this.price = price;
    this.currency = currency;
    this.canUsePaymentButton = false;
    this.rootElement = rootElement;
    this.existingPaymentMethod = paymentMethod;
  }

  async setup() {
    try {
      this.stripe = await loadStripe(this.publicKey, { stripeAccount: this.stripeAccount });
      this.elements = this.stripe.elements();

      this.setupCaptcha();
      this.setDefaultPaymentMethod();
      this.setupCard();
      this.setupPaymentButton();
      this.listenForPaymentButtonCompletion();
      this.listenForFormSubmission();

      this.setupPaymentMethodSwitch();
      this.setupUseExistingSwitch();

    }
    catch (error) {
      this.rootElement.querySelector('form').innerHTML = `
        <div class='text-left p-8'>
          <h3 class='font-bold text-lg'>Sorry, something went wrong!</h3>

          <p>
            Unfortunately, we couldn't load this form for you. You can try closing this window
            and opening it again.
          </p>
          <p>
            If you're still running into problems, please <a data-action='click->beacon-link#open' data-controller='beacon-link' href='mailto:support@supercast.com'>get in touch</a>.
          </p>
        </div>
      `;

      Appsignal.sendError(error, (span) => {
        span.setAction("setup");
      });
    }
  }

  setDefaultPaymentMethod() {
    // If we can use a button, setting up the button will change this to button.
    this.currentPaymentMethod = 'card';
  }

  setupUseExistingSwitch() {
    this.rootElement.querySelectorAll("input[name='use_existing_payment_method']").forEach(el => {
      el.addEventListener('click', this.setPaymentVisibility.bind(this));
    })
  }

  setPaymentVisibility() {
    const signUpButton = this.rootElement.querySelector('#signup-button');
    this.toggleSections(this.ALL_SECTIONS, 'none');
    if (this.useExistingCard()) {
      this.toggleSections(this.EXISTING_CARD_SECTIONS, 'block');
      signUpButton.disabled = false;
    } else if (this.currentPaymentMethod == 'button') {
      this.toggleSections(this.PAYMENT_REQUEST_SECTIONS, 'block');
      signUpButton.disabled = true;
    } else {
      this.toggleSections(this.CARD_SECTIONS, 'block');
      signUpButton.disabled = true;
    }
  }

  toggleSections(sections, display) {
    sections.forEach(section => {
      const el = this.rootElement.querySelector(section);
      if (el) { el.style.display = display }
    })
  }

  setupCaptcha() {
    this.hasCaptcha = this.rootElement.querySelector(".g-recaptcha");

    if (this.hasCaptcha) {
      window.captchaCallback = () => {
        const form = this.rootElement.querySelector('.stripe-form');
        form.submit();
      }
    }
  }

  setupCard() {
    this.card = this.elements.create('card');
    this.card.mount('#credit_card');
    this.listenForCardErrors();
  }

  listenForCardErrors() {
    const paymentErrorText = this.rootElement.querySelector('#payment-errors')
    const signUpButton = this.rootElement.querySelector('#signup-button');

    this.card.addEventListener('change', (e) => {
      if ( e.error ) {
        logEvent("Payment - Card Error", { message: e.error.message });
        // show validation to customer
        paymentErrorText.textContent = e.error.message;
        paymentErrorText.style.display = 'block';
        signUpButton.disabled = true;
      } else if ( e.complete ) {
        logEvent("Payment - Card Input Successful");
        // enable payment button
        paymentErrorText.style.display = 'none';
        signUpButton.disabled = false;
      }
    });
  }

  listenForCompleteUserInfo(form) {
    form.querySelectorAll("[required]").forEach(el => {
      if (el.value.length < 1 || (el.type == 'checkbox' && !el.checked)) {
        this.addInlineError(el, "This field is required");
      } else {
        this.removeInlineError(el);
      }
    });

    form.querySelectorAll("input[type='number'][min]").forEach(el => {
      if (el.value !== "" && el.value < el.min) {
        this.addInlineError(el, `This field must be at least ${el.min}`);
      } else {
        this.removeInlineError(el);
      }
    });

    // Additionally check e-mails
    // TODO: Move all e-mail inputs to type=email so we don't repeat this logic.
    const email = form.querySelector(".email-input")
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email) {
      if (!emailRegex.test(email.value.toLowerCase()) ) {
        this.addInlineError(email, "Email is not a valid email");
      } else { this.removeInlineError(email)}
    }

    form.querySelectorAll("input[type='email']").forEach(el => {
      if (!emailRegex.test(el.value.toLowerCase()) ) {
        this.addInlineError(el, "Email is not a valid email");
      } else { this.removeInlineError(el)}
    })

    // Validate fields that should not match (to_email and from_email on gift is an example)
    form.querySelectorAll("[data-notmatch]").forEach(el => {
      if (document.querySelector(el.dataset.notmatch).value == el.value) {
        this.addInlineError(el, el.dataset.notmatchMessage || `${el.value} is invalid`);
      }
    });

    // return false when there is no errors and terms is checked
    if (form.querySelector(".inline-error") === null) {
      return false;
    } else { return true; }
  }

  addInlineError(element, errorMessage) {
    const currentError = element.parentNode.querySelector(".inline-error")

    element.classList.add("input-error")
    if ( currentError === null ) {
      element.insertAdjacentHTML("afterend", `<small class='inline-error text-red-500 text-sm'>${errorMessage}</small>`)
    }
  }

  removeInlineError(element) {
    const currentError = element.parentNode.querySelector(".inline-error")

    if ( currentError !== null ) { currentError.remove(); }
    element.classList.remove("input-error")
  }


  listenForPaymentButtonCompletion() {
    this.paymentRequest.on('paymentmethod', (e) => {
      e.complete('success');
      this.paymentButtonPaymentMethod = e.paymentMethod
      this.fillFormFromPaymentMethod(e);
    });
  }

  async fillFormFromPaymentMethod(e) {
    const form = this.rootElement.querySelector('.stripe-form');

    if (e.payerName && e.payerName.indexOf(" ") > -1) {
      const trimmedName = e.payerName.trim();
      form.querySelector(".first-name-input").value = trimmedName.substring(0, trimmedName.lastIndexOf(' '));
      form.querySelector(".last-name-input").value = trimmedName.substring(trimmedName.lastIndexOf(' ') + 1, trimmedName.length);
    }
    if (e.payerEmail && e.payerEmail.length > 1) {
      form.querySelector(".email-input").value  = e.payerEmail;
    }

    if (
      form.querySelector(".first-name-input").value.length === 0 ||
      form.querySelector(".last-name-input").value.length === 0 ||
      form.querySelector(".email-input").value.length === 0
    ) {
      // We didn't get enough info from Stripe, show the name input and prevent submission
      form.querySelector('.name-container').style.display = "block";
      return;
    }

    if (form.elements["terms_of_use_accepted"] && form.elements["terms_of_use_accepted"].checked === false) {
      // Show the signup container so the uesr can accept terms of use and continue.
      form.querySelector('.signup-button-container').style.display = "block";
      form.querySelector('.payment-request-container').style.display = "none";
      form.querySelector('#signup-button').disabled = false;

      const paymentErrorText = this.rootElement.querySelector('#payment-errors');

      paymentErrorText.textContent = "Please accept the Supercast Terms of Use and Privacy Policy before continuing.";
      paymentErrorText.style.display = 'block';
      return;
    }

    await this.completeSubscription(form);
  }

  async getCurrencyAndAmount() {
    if (this.intentType == 'payment') {
      const intent = await this.stripe.retrievePaymentIntent();
      return { amount: intent.amount, currency: intent.currency };
    } else if (this.plan) {
      this.price
    }
  }

  async setupPaymentButton() {
    this.paymentRequest = this.stripe.paymentRequest({
      total: {
        label: 'Total',
        amount: this.price || 0, // if we're updating cards, we may not have an active sub.
      },
      country: 'US',
      currency: this.currency || "usd",  // if we're updating cards, we may not have an active sub.
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link'],
    });

    this.paymentButton = this.elements.create('paymentRequestButton', { paymentRequest: this.paymentRequest })
    const result = await this.paymentRequest.canMakePayment();
    if (result?.applePay || result?.googlePay) {
      this.canUsePaymentButton = true;
      this.currentPaymentMethod = 'button';
      this.paymentButton.mount('#payment-request-button');
    }

    this.rootElement.querySelector("#payment-request-button")?.addEventListener('click', () => {
      logEvent("Payment - Clicked Payment Button");
    });

    this.setPaymentVisibility();
  }

  useExistingCard() {
    return this.existingPaymentMethod &&
           this.rootElement.querySelector("#use_existing").checked;
  }

  setupPaymentMethodSwitch() {
    this.rootElement.querySelector('.alt-payment-text a').addEventListener('click', (e) => {
      logEvent("Payment - Switching to existing card");
      e.preventDefault();
      this.currentPaymentMethod = 'card';
      this.setPaymentVisibility();
    });
  }

  listenForFormSubmission() {
    const signupButton = this.rootElement.querySelector('#signup-button');

    signupButton.addEventListener('click', async (e) => {
      e.preventDefault();
      const form = e.target.closest('form');

      const existingErrorMessage = this.rootElement.querySelector('#existing-user-error')

      if ( (!existingErrorMessage !== null || existingErrorMessage.textContent.length === 0 )) {
        await this.completeSubscription(form);
    }
    });
  }

  async completeSubscription(form) {
    try {
      logEvent("Payment - Completing Subscription", { paymentMethod: this.currentPaymentMethod });

      if (this.listenForCompleteUserInfo(form)) {
        return false;
      }

      const signUpButton = this.rootElement.querySelector('#signup-button');
      signUpButton.disabled = true;

      if (this.rootElement.querySelector('.purchase-progress')) {
        form.style.display = "none";
        this.rootElement.querySelector('.purchase-progress').style.display = "block";
      }

      window.setTimeout(this.abortCompletion.bind(this), 60000);

      const firstName = form.querySelector('.first-name-input').value;
      const lastName = form.querySelector('.last-name-input').value;

      let paymentMethod;
      if (this.useExistingCard()) {
        paymentMethod = this.existingPaymentMethod.id
      } else if (this.currentPaymentMethod == 'card') {
        paymentMethod = {
          card: this.card,
          billing_details: {
            name: firstName + ' ' + lastName
          }
        }
      } else {
        paymentMethod = this.paymentButtonPaymentMethod.id;
      }

      // Confirm the card client-side so that we know we're OK on the server.
      const result = this.intentType == 'setup' ?
        await this.stripe.confirmCardSetup(this.intentSecret, { payment_method: paymentMethod }) :
        await this.stripe.confirmCardPayment(this.intentSecret, { payment_method: paymentMethod, setup_future_usage: 'off_session' });

      if (result.error) {
        this.showPaymentError(result.error.message);
      } else if (this.hasCaptcha && window.grecaptcha) {
        window.grecaptcha.execute();
      } else {
        form.submit();
      }
    } catch (error) {
      this.showPaymentError("Sorry! We were unable to complete your purchase. Please try again or <a href='https://support.supercast.com' data-controller='beacon-link' data-action='beacon-link#open'>contact us</a> for help.");
      console.log("Error when submitting payment", error);
      Appsignal.sendError(error, (span) => {
        span.setAction("completeSubscription");
      });
      return false;
    }
  }

  showPaymentError(message) {
    const form = this.rootElement.querySelector('.stripe-form');
    const paymentErrorText = this.rootElement.querySelector('#payment-errors');
    const signUpButton = this.rootElement.querySelector('#signup-button');

    if (this.rootElement.querySelector('.purchase-progress')) {
      form.style.display = "block";
      this.rootElement.querySelector('.purchase-progress').style.display = "none";
    }

    paymentErrorText.innerHTML = message;
    paymentErrorText.style.display = 'block';

    if (window.supercast) {
      window.supercast.trigger('pageload');
    }

    signUpButton.disabled = false;
  }

  abortCompletion() {
    // Intentionally slightly different from the other generic error so we can
    // distinguish them when users report.
    this.showPaymentError("Sorry! Your purchase could not be completed. Please try again or <a href='https://support.supercast.com' data-controller='beacon-link' data-action='beacon-link#open'>contact us</a> for help.");
  }
}
