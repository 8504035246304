import { Controller } from "stimulus";

function createStyleList(classString) {
  if (classString.length > 0) {
    return classString.split(" ")
  } else {
    return []
  }
}

export default class extends Controller {
  static targets = ["section", "arrow"]

  initialize() {
    // Grab the first section as the container.
    if (this.element.dataset.expandFirst == "true") {
      this.currentSection = this.element.querySelector("[data-accordion-container]");
    }
    this.activeStyles = createStyleList(this.element.dataset.activeStyles)
    this.inactiveStyles = createStyleList(this.element.dataset.inactiveStyles)
    this.showCurrentSection()
  }

  openSection(event) {
    this.currentSection = event.currentTarget.parentElement;
    this.showCurrentSection()
  }

  showCurrentSection() {
    this.sectionTargets.forEach((element) => {
      const container = element.closest("[data-accordion-container]");
      const arrow = container.querySelector(
        "[data-accordion-component-target='arrow']"
      );

      if (element.closest("section") != this.currentSection) {
        element.classList.add("hidden")
        container.classList.remove(...this.activeStyles)
        container.classList.add(...this.inactiveStyles)
        arrow.classList.remove("rotate-90")
      } else {
        element.classList.remove("hidden")
        container.classList.remove(...this.inactiveStyles)
        container.classList.add(...this.activeStyles)
        arrow.classList.add("rotate-90")
      }
    })
  }
}
