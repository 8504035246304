import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("Referral Source Controller connected");
    window.addEventListener("supercast-lock-play", this.lockPlay.bind(this));
  }

  lockPlay() {
    console.log("lockPlay");
    this.element.remove();
  }
}