export function delegate({ baseSelector, selector, eventName, handler }) {
  const baseElement = baseSelector ? document.querySelector(baseSelector) : document;

  baseElement.addEventListener(eventName, function (e) {
    for (let target = e.target; target && target != this; target = target.parentNode) {
      if (target.matches(selector)) {
        handler.call(target, e);
        break;
      }
    }
  });
}