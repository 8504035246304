import { Controller } from "stimulus";

export default class MediaProgressController extends Controller {
  connect() {
    this.updateFromLocalStorage();
    this.listenForTimeUpdates();
  }

  async updateFromLocalStorage() {
    const data = await window.playbackDb.getPlaybackData(this.itemTypeValue, this.itemIdValue);
    
    if (data) {
      this.update({ currentTime: data.currentTime, duration: data.duration });
    }
  }

  listenForTimeUpdates() {
    window.addEventListener("supercast-timeupdate", (event) => {
      if (event.detail.itemId == this.itemIdValue && event.detail.itemType == this.itemTypeValue) {
        this.update(event.detail);
      }
    })
  }

  update({ currentTime, duration }) {
    if (!currentTime || !duration) {
      return;
    }

    this.progressBarTarget.style.width = `${(currentTime / duration) * 100}%`;
    const durationRemainingInSeconds = duration - currentTime;
    
    if (durationRemainingInSeconds < 10) {
      this.durationTextTarget.textContent = "Played";
      this.progressBarContainerTarget.classList.add('hidden');
    } else if (durationRemainingInSeconds > 3600) {
      this.durationTextTarget.textContent = `${Math.floor(durationRemainingInSeconds / 3600)} h left`;
      this.progressBarContainerTarget.classList.remove('hidden');
    } else if (durationRemainingInSeconds > 60) {
      this.durationTextTarget.textContent = `${Math.floor(durationRemainingInSeconds / 60)} m left`;
      this.progressBarContainerTarget.classList.remove('hidden');
    } else {
      this.durationTextTarget.textContent = `${Math.floor(durationRemainingInSeconds)} s left`;
      this.progressBarContainerTarget.classList.remove('hidden');
    }
  }
}

MediaProgressController.values = { itemId: String, itemType: String };
MediaProgressController.targets = ['progressBar', 'durationText', 'progressBarContainer'];

