import { Controller } from "stimulus";

export default class TimestampComponent extends Controller {
  static targets = ["timestamp", "minutes", "seconds", "hours"];

  connect() {
    this.setHoursMinutesAndSeconds();


    this.hoursTarget.addEventListener("input", () => {
      this.maybeMoveFocusToMinutes();
      this.setTimestamp();
    });

    this.minutesTarget.addEventListener("input", () => {
      this.maybeMoveFocusToSeconds();
      this.setTimestamp();
    });

    this.secondsTarget.addEventListener("input", () => {
      this.setTimestamp();
    });
  }

  setHoursMinutesAndSeconds() {
    const timestamp = Math.round(this.timestampTarget.value);
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor((timestamp % 3600) / 60);
    const seconds = Math.floor(timestamp % 60);

    this.hoursTarget.value = hours == 0 ? "" : hours
    this.minutesTarget.value = minutes == 0 ? "" : minutes
    this.secondsTarget.value = seconds == 0 ? "" : seconds
  }

  setTimestamp() {
    const hours = this.hoursTarget.value || 0;
    const minutes = this.minutesTarget.value || 0;
    const seconds = this.secondsTarget.value || 0;
    const timestamp = (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
    this.timestampTarget.value = timestamp;
  }

  maybeMoveFocusToMinutes() {
    if (this.hoursTarget.value.length == 2) {
      this.minutesTarget.focus();
    }
  }

  maybeMoveFocusToSeconds() {
    if (this.minutesTarget.value.length == 2) {
      this.secondsTarget.focus();
    }
  }
}
