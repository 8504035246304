export function domain(subdomain, environment) {
  if (!subdomain) {
    if (window.Supercast && window.Supercast.currentSubdomain) {
      subdomain = window.Supercast.currentSubdomain;
    } else {
      subdomain = "app";
    }
  }

  let domain, secure;
  switch (environment) {
    case 'development':
      domain = 'supercast.test';
      secure = true;
      break;
    case 'test':
      domain = 'lvh.me:31337';
      secure = false;
      break;
    case 'staging':
      domain = 'staging.supercast.tech';
      secure = true;
      break;
    case 'production':
      domain = 'supercast.com';
      secure = true;
      break;
  }

  return `${secure ? 'https' : 'http'}://${(subdomain || 'app')}.${domain}`;
}

export function currentEnvironment() {
  switch (window.location.hostname.split(".").slice(-2).join(".")) {
    case 'supercast.test':
      return 'development';
    case 'lvh.me:31337':
      return 'test';
    case 'staging.supercast.tech':
      return 'staging';
    case 'supercast.com':
      return 'production';
  }
}
