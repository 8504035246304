/*
 * General purpose form utility controller. Adds the following actions:
 * - submit: submits the form
*/
import { Controller } from "stimulus"
import debounce from "debounce";

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.refreshAction = this.formTarget.dataset.refreshaction || window.location.pathname;
    this.frame = this.formTarget.dataset.turboFrame || (this.element.closest("turbo-frame") && this.element.closest("turbo-frame").id) || "_top";
    this.disableButtonsOnSubmit();
    this.reenableButtonsOnVisitFinished();

    this.debouncedSubmit = debounce(this.submit, 500);
    this.debouncedRefresh = debounce(this.refresh, 500);
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  refresh() {
    const url = new URL(this.refreshAction, window.location.origin);
    // Set the querystring on url equal to the data from formTarget
    const formData = new FormData(this.formTarget);
    for (const [key, value] of formData.entries()) {
      url.searchParams.set(key, value);
    }

    window.Turbo.visit(url, { frame: this.frame })
  }

  disableButtonsOnSubmit() {
    this.formTarget.addEventListener('submit', () => {
      const buttons = this.formTarget.querySelectorAll('[type="submit"]');

      buttons.forEach((button) => {
        button.disabled = true;
      });
    });
  }

  reenableButtonsOnVisitFinished() {
    this.formTarget.addEventListener('turbo:submit-end', () => {
      const buttons = this.formTarget.querySelectorAll('[type="submit"]');
      buttons.forEach((button) => {
        button.disabled = false;
      });
    });
  }
}