import { Controller } from "stimulus";

export default class TusUploadComponentController extends Controller {
  static values = {
    mimeTypes: Array,
    maxFileSize: Number,
    presignEndpoint: String,
  }

  static targets = [
    "field",
    "metaField",
    "uppy",
  ]

  connect() {
    this.setupUppy();
  }

  setupUppy() {
    const uppy = new window.Uppy.Uppy(this.uppyConfig());
    this.attachDashboard(uppy);
    this.attachTus(uppy);
  }

  uppyConfig() {
    return {
      autoProceed: true,
      restrictions: {
        maxFileSize: this.maxFileSizeValue,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: this.mimeTypesValue,
      },
      onBeforeFileAdded: this.onBeforeFileAdded.bind(this)
    }
  }

  onBeforeFileAdded({ data }, files) {

    var objectURL = URL.createObjectURL(data);
    var audio = document.createElement('audio');
    audio.src = objectURL;

    audio.onloadedmetadata = () => {
      this.duration = audio.duration

      URL.revokeObjectURL(objectURL)
    }

    return true
  }

  async fileAdded(file) {
    await fetch(this.presignEndpointValue)
      .then(response => response.json())
      .then(data => file.meta.bunny = data)
  }

  disableFormSubmits() {
    this.element.closest("form").querySelectorAll('input[type="submit"]').forEach((button) => {
      button.disabled = true;
    });
  }

  attachDashboard(uppy) {
    uppy.use(window.Uppy.Dashboard, {
      inline: true,
      target: this.uppyTarget,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      height: 200,
      width: '100%',
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'file name' },
      ],
      browserBackButtonClose: true
    });
  }

  enableFormSubmits() {
    this.element.closest("form").querySelectorAll('input[type="submit"]').forEach((button) => {
      button.disabled = false;
    });
  }

  setId(result) {
    var file = result.successful[0];
    if (this.presignEndpointValue === "/s3/params") {
      this.fieldTarget.value = file.id;
    } else {
      this.fieldTarget.value = file.meta.bunny.video_id;
    }
  }

  setMetadata(file, data) {
    var podcast_data = {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
      duration: this.duration
    }

    // emit a custom event with the uploaded file metadata
    // so other components can react to it and update their state
    const objectUrl = URL.createObjectURL(file.data);
    podcast_data.url = objectUrl;
    this.metaFieldTarget.value = JSON.stringify(podcast_data);
    const event = new CustomEvent('metadata-updated', { detail: podcast_data });
    this.metaFieldTarget.dispatchEvent(event);
  }

  attachTus(uppy) {
    let tus;

    // HACK: This can be cleaned up once we don't have speed issues with bunny.
    if (this.presignEndpointValue === "/s3/params") {
      tus = uppy.use(window.Uppy.AwsS3, {
        companionUrl: "/",
      });
    } else {
      tus = uppy.use(window.Uppy.Tus, {
        endpoint: "https://video.bunnycdn.com/tusupload",
        async onBeforeRequest(req, file) {
          var data = file.meta.bunny

          req.setHeader('AuthorizationSignature', data.signature);
          req.setHeader('AuthorizationExpire', data.expire);
          req.setHeader('VideoId', data.video_id);
          req.setHeader('LibraryId', data.library_id);
        },
      });
    }

    if (this.hasMetaFieldTarget) {
      tus.on('file-added', this.fileAdded.bind(this))
        .on('upload', this.disableFormSubmits.bind(this))
        .on('complete', this.enableFormSubmits.bind(this))
        .on('complete', this.setId.bind(this))
        .on('upload-success', this.setMetadata.bind(this));
    } else {
      tus.on('file-added', this.fileAdded.bind(this))
        .on('upload', this.disableFormSubmits.bind(this))
        .on('complete', this.enableFormSubmits.bind(this))
        .on('complete', this.setId.bind(this));
    }
  }
}
