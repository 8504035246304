import { Controller } from "stimulus"
import { default as TomSelect } from "tom-select"

export default class TypeaheadComponent extends Controller {
  static targets = ["select"]
  static values = {
    endpoint: String,
    delay: Number,
    itemLabel: String,
    itemValue: String,
    value: Array, // Should be in the format of [{ value: "value", label: "label" }]
    fieldOrName: String,
    create: Boolean, // If true, new options can be created. They will have a value of "new:(text entered)"
    options: Array, // Should be in the format of [{ value: "value", label: "label" }]
  }

  // Lifecycle

  async connect() {
    this.initTomSelect()
    const id = this.selectTarget.id
    const event = new CustomEvent("typeahead-component:ready", {
      detail: {
        selectElementId: id,
      }
    })
    window.dispatchEvent(event)
  }

  url = (query) => {
    const { protocol, host } = window.location
    let base = ""
    if (this.endpointValue.startsWith("http")) {
      base = new URL(this.endpointValue)
    } else {
      base = new URL(`${protocol}//${host}${this.endpointValue}`)
    }
    base.searchParams.append("query", query)
    return `${base.toString()}`
  }

  // Helpers

  fetchData = (query, callback) => {
    fetch(this.url(query))
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map(item => {
          return {
            value: item[this.itemValueValue],
            label: item[this.itemLabelValue]
          }
        })
        const options = this.buildOptions(transformedData)
        callback(options)
      })
      .catch(() => callback())

  }

  buildOptions = (data) => {
    return data.map(item => {
      return {
        value: item.value,
        label: item.label
      }
    })
  }

  renderOption() {
    return {
      option: function (item, escape) {
        return `<div>${escape(item.label)}</div>`
      }
    }
  }

  handleDefaultValue = (tomSelect) => {
    if (this.valueValue) {
      this.valueValue.filter(n => n).forEach(item => {
        tomSelect.addOption({
          value: item.value,
          label: item.label
        })
        tomSelect.addItem(item.value)
      })
    }
  }

  selectedItems() {
    const tom = this.selectTarget.tomselect
    const items = tom.items
    return Object.values(tom.options).filter(option => items.find((i) => i == option.value))
  }

  clearItems() {
    const tom = this.selectTarget.tomselect
    tom.clear()
  }

  initTomSelect() {
    let config = {
      valueField: 'value',
      labelField: 'label',
      searchField: 'label',
      highlight: false,
      create: this.createValue,
    }

    if (this.optionsValue && this.optionsValue.length > 0) {
      config.options = this.optionsValue
      config.maxItems = null
    } else {
      config.shouldLoad = function(query){
        return query.length >= 1;
      }
      config.load = this.fetchData
      config.render = this.renderOption()
    }

    if (this.createValue) {
      config.create = (input) => ({
        value: "new:" + input,
        label: input
      })
    }

    const tom = new TomSelect(this.selectTarget, config)

    // clear all options if no options were provided
    if (this.optionsValue.length === 0) {
      tom.clearOptions()
    }
    // add item in case there is a default value and label
    this.handleDefaultValue(tom)
  }
}

