import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["audio", "meta", "controls", "timeline", "currentTime", "duration", "playIcon", "pauseIcon"]

  initialize() {
    this.audioTarget.addEventListener("canplay", () => {
      this.timelineTarget.max = Math.floor(this.audioTarget.duration)
      this.showAudioPlayer()
      this.audioTarget.play()
    })
    this.audioTarget.addEventListener("timeupdate", () => {
      this.timelineTarget.value = Math.floor(this.audioTarget.currentTime)
      this.currentTimeTarget.innerHTML = this.formatTime(this.audioTarget.currentTime)
      this.paintProgress()
    })
    this.audioTarget.addEventListener("durationchange", () => {
      this.timelineTarget.max = Math.floor(this.audioTarget.duration)
      this.durationTarget.innerHTML = this.formatTime(this.audioTarget.duration)
    })
    this.audioTarget.addEventListener("play", () => this.playing())
    this.audioTarget.addEventListener("pause", () => this.paused())
    this.audioTarget.addEventListener("ended", () => this.paused())
    this.timelineTarget.addEventListener("change", () => {
      this.audioTarget.currentTime = this.timelineTarget.value
    })
    this.timelineTarget.addEventListener("input", () => this.paintProgress())
  }

  showAudioPlayer() {
    this.metaTarget.classList.add("hidden")
    this.controlsTarget.classList.remove("hidden")
  }

  togglePlayer() {
    if (this.audioTarget.readyState === 0) {
      this.audioTarget.load()
      return
    }

    this.audioTarget.paused ? this.audioTarget.play() : this.audioTarget.pause()
  }

  playing() {
    this.playIconTarget.classList.add("hidden")
    this.pauseIconTarget.classList.remove("hidden")
  }

  paused() {
    this.pauseIconTarget.classList.add("hidden")
    this.playIconTarget.classList.remove("hidden")
  }

  paintProgress() {
    const done = (this.timelineTarget.value - this.timelineTarget.min) * 100
    const total = this.timelineTarget.max - this.timelineTarget.min
    this.timelineTarget.style.backgroundSize = (done / total) + "% 100%"
  }

  formatTime(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = Math.floor(totalSeconds % 60).toString().padStart(2, '0')
    return `${minutes}:${seconds}`
  }
}
