// This is a smaller version of the supercast defined at app/assets/javascripts/core/supercast.js, intended for embeds only.

// Allow a NodeList to be iterated on (forEach, map, filter, etc)
/* jshint proto: true */
NodeList.prototype.__proto__ = Array.prototype;

const SC = (function () {
  let events = {
    pageload: [],
    'modal-loaded': [],
  };

  const initEventListeners = function () {
    document.addEventListener('turbo:load', () => {
      events.pageload.forEach(event => event.call());
    });

    // Add any new listeners here, along
    // with their defined `events` array
  };

  function Supercast() {
    initEventListeners.call(this);
  }

  Supercast.prototype.on = function (eventName, ...callbacks) {
    callbacks = callbacks.filter(c => typeof c === 'function');
    events[eventName] = events[eventName] || [];
    events[eventName].push(...callbacks);
  };

  Supercast.prototype.trigger = function (eventName, payload) {
    events[eventName].forEach(cb => cb(payload));
  };

  return Supercast;
})();

window.supercast = new SC();
