async function fetchGeocodeDataImpl() {
  const response = await fetch("https://app.supercast.com/api/geocode");
  const data = await response.json();
  return data;
}

// Wraps geocode fetching in a singleton pattern to prevent multiple requests
async function fetchGeocodeData() {
  if (window.geocodeFetchPromise) {
    return window.geocodeFetchPromise;
  }
  window.geocodeFetchPromise = fetchGeocodeDataImpl();
  return window.geocodeFetchPromise;
}

export default async function geocode() {
  if (window.localStorage.getItem("geocode")) {
    return JSON.parse(window.localStorage.getItem("geocode"));
  } else {
    const data = await fetchGeocodeData();
    window.localStorage.setItem("geocode", JSON.stringify(data));
    return data;
  }
}