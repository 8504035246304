import { Controller } from 'stimulus';

export default class LoginController extends Controller {
  static targets = ["magicLink", "email"]
  static MAGIC_LINK_PATH = ""

  initialize() {
    this.triggerMagicLink();
    this.enableButtonsOnEntry();
  }

  enableButtonsOnEntry() {
    this.emailTarget.addEventListener('input', e => {
      const isDisabled = e.target.value.length == 0;
      this.element.querySelector("input[type='submit']").disabled = isDisabled;
      this.magicLinkTarget.disabled = isDisabled;
    });
  }

  triggerMagicLink() {
    this.magicLinkTarget.addEventListener('click', () => {
      this.element.action = this.magicLinkTarget.dataset.href;
      this.element.submit();
    });
  }
}
