import { Controller } from 'stimulus';

/* Provides functionality to update content inline, as part of previewing a form. To use, attach this controller
   to an element that wraps all content that could change, and mark all fields that may be edited with 
   data-preview, with the value set to the name of the form field that it corresponds to. */
export default class PreviewController extends Controller {

  connect() {
    window.addEventListener("message", this.updateParameters.bind(this));
  }

  // event.details contains a hash of updated properties. We should find an element with data-preview-{{property}}
  // and replace its contents
  updateParameters(event) {
    if (event.data.name !== 'supercast-preview-update') {
      return;
    }

    for (const property in event.data.previewData) {
      const element = this.element.querySelector(`[data-preview='${property}']`);
      if (element) {
        element.innerHTML = event.data.previewData[property];
      }

      const toggleDisplayElement = this.element.querySelector(`[data-preview-toggle-display='${property}']`);
      if (toggleDisplayElement) {
        if (event.data.previewData[property] == "1") {
          toggleDisplayElement.classList.remove("hidden");
        } else {
          toggleDisplayElement.classList.add("hidden");
        }
      }
    }
  }
}
