import { Controller } from "stimulus";

export default class AddButtonComponent extends Controller {
  connect() {
    this.setupButtonColorCyle();
  }

  // Cycle between common podcast icon colors (fuscia-600->green-500->orange-500) every 2 seconds
  setupButtonColorCyle() {
    const colors = this.colorsValue.split(",");
    const ringColors = this.ringColorsValue.split(",");

    if (colors.length > 1) {
      let colorIndex = 0;
      setInterval(() => {
        this.buttonOuterRingTarget.classList.remove(ringColors[colorIndex]);
        this.buttonTarget.classList.remove(colors[colorIndex]);
        colorIndex = (colorIndex + 1) % colors.length;
        this.buttonOuterRingTarget.classList.add(ringColors[colorIndex]);
        this.buttonTarget.classList.add(colors[colorIndex]);
      }, 2000);
    }
  }

  setStatus(status) {
    if (status == "loading") {
      this.defaultTarget.classList.add("hidden");
      this.loadingTarget.classList.remove("hidden");
      this.doneTarget.classList.add("hidden");
    } else if (status == "done") {
      this.defaultTarget.classList.add("hidden");
      this.loadingTarget.classList.add("hidden");
      this.doneTarget.classList.remove("hidden");
    } else {
      this.defaultTarget.classList.remove("hidden");
      this.loadingTarget.classList.add("hidden");
      this.doneTarget.classList.add("hidden");
    }
  }
}

AddButtonComponent.targets = ["button", "buttonOuterRing", "default", "loading", "done"];
AddButtonComponent.values = {
  colors: String,
  ringColors: String,
};