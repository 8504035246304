import { Controller } from "stimulus";
import { logEvent } from "utils/analytics";

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.click.bind(this));
  }

  click() {
    logEvent("Clicked Button", {
      buttonText: this.element.textContent,
      url: this.element.href
    })
  }
}
