import { Controller } from 'stimulus'

export default class VisiblityToggle extends Controller {
  static targets = ['target', 'input'];

  connect() {
    if (this.element.dataset["visibilityTargetInitialToggle"] !== "false") {
      this.toggle({ preventDefault: () => { } });
    }
  }

  toggle(e) {
    e.preventDefault();

    let currentValue = null;
    if (this.hasInputTarget) {
      switch(this.inputTarget.type) {
        case "checkbox":
          currentValue = this.inputTarget.checked;
          break;
        case "radio":
          currentValue = this.inputTargets.filter((input) => input.checked).map((input) => input.value);
          break;
        default:
          currentValue = this.inputTarget.value;
          break;
      }
      currentValue = currentValue.toString();
    }

    this.targetTargets.forEach((target) => {
      target.classList.add('hidden');

      if ((!target.dataset.triggerValue) || target.dataset.triggerValue.split(',').includes(currentValue)) {
        target.classList.remove('hidden');
      }
    });
  }
}
